<style lang="scss" scoped>
.icon {
	width: 1.45rem;
	height: 1.45rem;
	margin: 1.6rem auto 0.5rem;
	background: url(../../assets/icon/success.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
}

.text {
	font-size: 0.3rem;
	color: #052433;
	line-height: 0.3rem;
	font-weight: bold;
	text-align: center;
}

.btn {
	width: 2.26rem;
	height: 0.8rem;
	margin: 1.36rem auto 0;
	background-color: $color_main;
	font-size: 0.3rem;
	line-height: 0.8rem;
	color: #fff;
	font-weight: bold;
	text-align: center;
	border-radius: 0.08rem;
	letter-spacing: 0.1em;
}
</style>

<template>
	<div class="pay_success">
		<top-nav @back="back" :bgColor="$store.state.cps_brindInfo.brandColor">{{ $t('paySuccess') }}</top-nav>
		<div class="icon"></div>
		<div class="text" v-text="text">恭喜，您的投保已成功！</div>
		<div v-if="protectBillUrl" class="btn" @click="download">保单下载</div>
		<fileDownload :show.sync="show" :url="protectBillUrl" @update:show="val => (show = val)" name="保单" />
	</div>
</template>

<script>
import fileDownload from '@/components/fileDownload.vue';

export default {
	name: 'cpspaySuccess',
	components: {
		fileDownload,
	},
	props: {
		protectBillUrl: {
			type: String,
		},

		text: {
			type: String,
			required: false,
			default: '恭喜，您的投保已成功！',
		},
	},
	data() {
		return {
			show: false,
		};
	},
	methods: {
		download() {
			// window.open(protectBillUrl);
			this.show = true;
		},

		back() {
			// this.$router.push('cpsHome')
			// window.location.reload()
			window.location.href = window.localStorage.getItem('homeUrl');
			// this.$router.push(window.localStorage.getItem('homeUrl'))
		},
	},
};
</script>
